import {
    Box,
    CloseButton,
    // Button,
    Container,
    Divider,
    Flex,
    Heading,
    Icon,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
  } from "@chakra-ui/react";
  import React, { useEffect, useRef, useState } from "react";
  // import { AiOutlineSearch } from "react-icons/ai";
  import styles from "../style/JoinUs.module.css";
  import { useThrottle } from "use-throttle";
  import { useNavigate } from "react-router-dom";
  import { FaDoorOpen } from "react-icons/fa6";
  import { IoStorefront } from "react-icons/io5";
  import { FaStore } from "react-icons/fa";

  
  const carLuxe = 'https://res.cloudinary.com/dn0hjcpmq/image/upload/v1726303356/qgfreqpzkqhygmpilmey.webp';
  const bikeLuxe = 'https://res.cloudinary.com/dn0hjcpmq/image/upload/v1726303355/ynfnm0vshcop8xsve4ke.jpg';
    
  // Small services logo
  const cardData = [
    {
      id: 1,
      logo: carLuxe,
      title: 'car',
      service: "Car Wash",
    },
    {
      id: 2,
      logo: bikeLuxe,
      title: 'bike',
      service: "Bike Wash",
    },
  ];
  
  const JoinUsTopSection = ({ loading, setLoading, onChange, suggestions }) => {
    const selectedCity = localStorage.getItem("location");
    const [inputText, setInputText] = useState("");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [active, setActive] = useState(0);
    const scrollRef = useRef();
    const throttledText = useThrottle(inputText, 1000);
    const navigate=useNavigate()
    useEffect(() => {
      onChange(throttledText);
    }, [throttledText, onChange]);

    const handleRegisterClick = () => {
        onOpen();
    };
    
  
    const handleInputChange = (e) => {
      setInputText(e.target.value);
      setLoading(true);
    };
  
    // const handleClear = () => {
    //   setInputText("");
    //   onChange("");
    //   setLoading(false);
    // };
    
    const searchResult = (item) => {
      navigate(`/commingsoon`)
    };
  
    return (
      <Box>
        <Box className={styles.homeTopBox}>
          <Heading
            as={"h1"}
            fontSize={{base: 'xl', md: "48px", lg: "48px", xl: "48px"}}
            fontWeight="500"
            color="white"
            lineHeight={{ lg: "72px"}}
          >
            Partner with WHEELZ 365<br/> and grow your business
          </Heading>
            <Box
                bg={'whiteAlpha.900'}
                borderRadius={'10'}
                border={'4px'}
                padding={{base: '0', md: '2', lg: '2', xl: '3'}}
                color={'blackAlpha.900'}
                backgroundColor={'black'}
                width={{base: 'auto', lg: 'auto', xl: 'auto'}}
                flexDirection={'row'}
                alignItems={'cetner'}
                onClick={() => handleRegisterClick()}
            
            >
                <Text textColor={'white'} fontSize={{base: 'xs', md: 'md', lg:'xl', xl: 'xl'}} fontWeight="500" textAlign={'center'}>Register now</Text>
            </Box>
        </Box>


        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent position="relative"
                width={{
                base: "80%", // 90% width on small devices
                md: "70%",  // 70% width on medium devices
                lg: "32%",  // 60% width on large devices
                xl: "32%",  // 50% width on extra-large devices
                }}
                maxWidth="900px" // Set a maximum width for larger screens
                borderRadius="3xl"
            >
                <CloseButton
                    position="absolute"
                    right="8px"
                    top="8px"
                    size="lg"
                    onClick={onClose}
                />
                <ModalHeader width='70%' textAlign='left' fontWeight='800'>Select the service you want to register for</ModalHeader>
                <Divider />
                <ModalBody >
                    <Flex
                        flexDirection="column"
                    >
                        <Flex flexDirection='row' mb={{base: 1, md: 2, lg: 3, xl: 3}} alignItems="flex-start" padding={{base:'13px'}} shadow={'xl'} borderRadius={'xl'} bgGradient="linear(to-r, white, gray.100, gray.200)">
                            <Box textAlign='left'>
                                <Text fontSize={{ base: 'sm', md: 'md', lg: 'md', xl: 'lg' }} fontWeight="600">In Store Service</Text>
                                <Text fontSize={{ base: 'sm', md: 'md', lg: 'md', xl: 'lg' }} fontWeight={'400'}>Reach to wider audience through Wheelz 365</Text>
                                <Text fontSize={{ base: 'sm', md: 'sm', lg: 'sm', xl: 'md' }} fontWeight='600' textColor={'blue'}>Register Now</Text>
                            </Box>
                            <Box>
                                <Icon
                                    as={FaStore}
                                    w={{ base: 12, md: 12, lg: 12, xl: 12 }}
                                    h={{ base: 12, md: 12, lg: 12, xl: 12 }}
                                    ml={{ base: 4, md: 6, lg: 8, xl: 10 }}
                                    mt={{ base: 2, md: 3, lg: 4, xl: 5 }}
                                    color="black.400"
                                    fontSize={{ base: '1', md: '1', lg: '1', xl: '1'}}
                                />
                            </Box>
                        </Flex>
                        <Flex flexDirection='row' alignItems="flex-start" mb={{base: 1, md: 2, lg: 3, xl: 3}} padding={{base:'13px'}} shadow={'xl'} borderRadius={'xl'} bgGradient="linear(to-r, white, gray.100, gray.200)">
                            <Box textAlign='left'>
                                <Text fontSize={{ base: 'sm', md: 'md', lg: 'lg', xl: 'lg' }} fontWeight="600">Door Step Service</Text>
                                <Text fontSize={{ base: 'sm', md: 'md', lg: 'md', xl: 'lg' }} fontWeight={'400'}>Reach to wider audience through Wheelz 365</Text>
                                <Text fontSize={{ base: 'sm', md: 'sm', lg: 'sm', xl: 'md' }} fontWeight='600' textColor={'blue'}>Register Now</Text>
                            </Box>
                            <Box>
                                <Icon
                                    as={FaDoorOpen}
                                    w={{ base: 12, md: 12, lg: 12, xl: 12 }}
                                    h={{ base: 12, md: 12, lg: 12, xl: 12 }}
                                    ml={{ base: 4, md: 6, lg: 8, xl: 10 }}
                                    mt={{ base: 2, md: 3, lg: 4, xl: 5 }}
                                    color="black.400"
                                    fontSize={{ base: '1', md: '1', lg: '1', xl: '1'}}
                                />
                            </Box>
                        </Flex>
                        <Flex flexDirection='row' alignItems="flex-start" padding={{base:'13px'}} shadow={'xl'} borderRadius={'xl'} bgGradient="linear(to-r, white, gray.100, gray.200)">
                            <Box textAlign='left'>
                                <Text fontSize={{ base: 'sm', md: 'md', lg: 'lg', xl: 'lg' }} fontWeight="600">Both In Store & Door Step</Text>
                                <Text fontSize={{ base: 'sm', md: 'md', lg: 'md', xl: 'lg' }} fontWeight={'400'}>Reach to wider audience through Wheelz 365</Text>
                                <Text fontSize={{ base: 'sm', md: 'sm', lg: 'sm', xl: 'md' }} fontWeight='600' textColor={'blue'}>Register Now</Text>
                            </Box>
                            <Box>
                                <Icon
                                    as={IoStorefront}
                                    w={{ base: 12, md: 12, lg: 12, xl: 12 }}
                                    h={{ base: 12, md: 12, lg: 12, xl: 12 }}
                                    ml={{ base: 4, md: 6, lg: 8, xl: 10 }}
                                    mt={{ base: 2, md: 3, lg: 4, xl: 5 }}
                                    color="black.400"
                                    fontSize={{ base: '1', md: '1', lg: '1', xl: '1'}}
                                />
                            </Box>

                        </Flex>
                    </Flex>
            </ModalBody>

            <ModalFooter>
            </ModalFooter>
            </ModalContent>
        </Modal>
      </Box>
    );
  };
  
  export default JoinUsTopSection;