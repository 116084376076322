import {
  Box,
  Text,
  Button,
  Flex,
} from '@chakra-ui/react';
import { PhoneIcon, EmailIcon } from '@chakra-ui/icons';
import { ImGoogle } from "react-icons/im";
import { useEffect, useState } from 'react';
import SigninCard from '../component/signincard.component';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsGoogleLogin, selectIsLoggedIn, selectPendingProfile, selectShowSignInComponent, selectUserProfile, setIsGoogleLogin, setIsLoggedIn, setPendingProfile, setShowSignInComponent, setShowSingInComponent, setUserProfile } from '../reducers';
import Profile from './profile.page';
import AddressForm from '../component/addressform.component';
import { supabase } from '../utils/supabase.client';
import signinService from '../service/signin.service';
import profileService from '../service/profile.service';
import { useNavigate } from 'react-router-dom';



const LoginPage = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isEnableSignInComponent = useSelector(selectShowSignInComponent);
  const [defalultLogingOptions, setDefalultLogingOptions] = useState(true);
  const [tag, setTag] = useState('EMAIL ID')
  const [profileDeatils, setProfileDetails] = useState('');
  const pendingProfile = useSelector(selectPendingProfile);
  const userProfile = useSelector(selectUserProfile);
  const isGoogleLoggedIn = useSelector(selectIsGoogleLogin);
  const [address, setAddress] = useState(null);
  const isLoggedIn = useSelector(selectIsLoggedIn) || false;
  const [storedData, setStoredData] = useState(null);
  const [signInMethod, setSignInMethod] = useState(null);
  const [userType, setUserType] = useState(null);
  const [addressPresent, setAddressPresent] = useState(false);
  const [mountData, setMountData] = useState(true);
  const [storedName, setStoredName] = useState(localStorage.getItem('sb-bshrfukmdupmguaegojl-auth-token'));

  const handleOnClick = (text) => {
    setTag(text)
    dispatch(setIsGoogleLogin(false));
    dispatch(setShowSignInComponent(false));
  }

  const handleGoogleSignIn =  async () => {
    const result  = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: `http://localhost:8000/login`,
      }
    });
    if(result.error){
      alert(`Something went wrong, please try again!`);
    }
    dispatch(setIsGoogleLogin(true));
    dispatch(setShowSignInComponent(false));
    
  }

  const updateFlag = async () => {
      console.log('here');
      const data1 = localStorage.getItem('sb-bshrfukmdupmguaegojl-auth-token');
      if(!data1)return;
      const data = JSON.parse(data1);
      const user = data.user;
      const access_token = data.access_token;
      const expires_at = data.expires_at;
      const refresh_token = data.refresh_token;
      const login_id = user.id;
      const isAuthenticated = expires_at*1000 > new Date().getTime() && user.aud === 'authenticated';

      if(isAuthenticated) {
        setSignInMethod('supabase_google');
        setDefalultLogingOptions(false);
        dispatch(setIsLoggedIn(true));
        dispatch(setUserProfile({
            ...userProfile,
            login_id: login_id,
            email: user.user_metadata.email,
            user_name: user?.user_metadata?.name

        }))
        //check if user already present in db or not
        const user_details = await profileService.fetchProfileDetailsByLoginId(login_id);
        // console.log(user_details);
        if(user_details){
          setUserType('Existing');
          if(user_details.user_status === 'ACTIVE') {
            dispatch(setPendingProfile(false));
            dispatch(setUserProfile({
              ...userProfile,
              phone_number: user_details?.user_phone_no,
              user_id: user_details?.user_id,
              isAddressPresent: user_details?.isAddressPresent || 'false'
            }))
            setAddressPresent(user_details?.isAddressPresent);
          } else if(user_details.user_status === 'SUSPENDED' || user_details.user_status === 'DEACTIVATED'){
            alert(`Your profile is ${user_details.status} due to ${user_details.inactive_reason}`);
          }
        } else {
          //check from backend on profile stats
          setUserType('New')
          const result = await signinService.signup(user.user_metadata.email, access_token, signInMethod, login_id);
          if(!result.status) throw new Error(`Error in singinup` + result.error)
          dispatch(setUserProfile({
              ...userProfile,
              user_id: result.data.user_id,
              email: user.user_metadata.email,
              user_name: user?.user_metadata?.name,
              isAddressPresent: user_details?.isAddressPresent || 'false'
          }));
          dispatch(setPendingProfile(true));
        }

      }
  }

  const checkLocalStorage = () => {
      const timer = setTimeout(() => {
        const data = localStorage.getItem('sb-bshrfukmdupmguaegojl-auth-token');
        if(data && !isLoggedIn) {
          setMountData(false);
          updateFlag();
        }
      }, 1000);
      return () => clearTimeout(timer);
  }

  const waitFunction = async () => {
    await checkLocalStorage();
  }

  useEffect(() => {
    const loadSaved = async () => {
      console.log('HI');
      const data = await localStorage.getItem('sb-bshrfukmdupmguaegojl-auth-token');
      setStoredData(data);
    }
    loadSaved();
    console.log(storedData);
    // waitFunction();
    // if(addressPresent && isLoggedIn && !pendingProfile){
    //   navigate('/');
    // }
    
  }, [storedData]);


  return (
    <Box bg="white" h="100vh" p={4}>
      <Flex direction="column" align="center" h="full">
        
        {(isEnableSignInComponent)  && 
        < >
        <Text fontWeight='semibold' fontSize='2xl' width={{base: '70%', md: '25%'}}>What's your phone number or email?</Text>
        <Button 
          padding='2'
          margin='2'
          borderColor="gray.200"
          borderRadius="md"
          width={{base: '70%',md: '25%'}}
          py={4}
          px={6}
          leftIcon={<EmailIcon margin='1'/>}
          onClick={() => handleOnClick('EMAIL ID')}
        >
          Sign In with Email
        </Button>

        <Button 
          padding='2'
          margin='2'
          borderColor="gray.200"
          borderRadius="md"
          width={{base: '70%',md: '25%'}}
          py={4}
          px={6}
          leftIcon={<PhoneIcon margin='1'/>}
          onClick={() => handleOnClick('PHONE NUMBER')}
        >
          Sign In with Phone
        </Button>
        <Text fontWeight='semibold'>or</Text>
        <Button 
          padding='2'
          margin='2'
          borderColor="gray.200"
          borderRadius="md"
          width={{base: '70%',md: '25%'}}
          py={4}
          px={6}
          leftIcon={<ImGoogle style={{margin:'-1', marginRight:'-5'}}/>}
          onClick={() => handleGoogleSignIn()}
        >
          Sign In with Google
        </Button>
        </>}
        {(!isEnableSignInComponent && !isLoggedIn && !isGoogleLoggedIn ) && <SigninCard tag={tag}/>}
        {(pendingProfile && isLoggedIn) &&  <Profile updateValueText={setProfileDetails} screen_name='sigin_up' user_type={userType} />}
        {!address && !pendingProfile && isLoggedIn && !userProfile?.isAddressPresent && <AddressForm updateValueText={setAddress}/>}
        {false && 
        <Box>
        <Button 
          padding='2'
          margin='2'
          borderColor="gray.200"
          borderRadius="md"
          width='25%'
          py={4}
          px={6}
          leftIcon={<PhoneIcon margin='1'/>}
        >
          Sign In with Phone otp
        </Button>
        </Box>}
      </Flex>
    </Box>
  );
};

export default LoginPage;