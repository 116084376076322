import { Box, Divider, Flex, Heading, Text, Icon } from "@chakra-ui/react";
import React from "react";
import styles from "../style/Home.module.css";
import HomePageNav from "./homepagenav.component";
import { MdGroups } from "react-icons/md";
import { IoIosMail } from "react-icons/io";
import { SiTrustpilot } from "react-icons/si";




const bikeWashPng = 'https://res.cloudinary.com/dn0hjcpmq/image/upload/v1726303357/wzbhjt24kpftscuowit3.jpg';
const carWash = 'https://res.cloudinary.com/dn0hjcpmq/image/upload/v1727982353/carWash_uh4cxh.jpg';
const dailyPass = 'https://res.cloudinary.com/dn0hjcpmq/image/upload/v1727982396/daily_pass_bw0qec.jpg';
//service listing array

const UXluxury = [
  {
    logo: bikeWashPng,
    services: "Bike Wash",
    text1: "exterior cleaning -",
    text2: "6 days in a week",
    text3: "interiro cleaning -",
    text4: "once a week",
    price: "₹399/-",
    text5: "per month",
  },
  {
    logo: carWash,
    services: "Car Wash",
    text1: "exterior cleaning -",
    text2: "6 days in a week",
    text3: "interiro cleaning -",
    text4: "once a week",
    price: "₹699/-",
    text5: "per month",
  },
  {
    logo: dailyPass,
    services: "Daily Pass",
    text1: "interior and exterior",
    text2: "car & bike cleaning",
    text3: "single day pass",
    text4: "exclusive for",
    price: "₹100/-",
    text5: "per day",
  }
];

const JoinUsMidComponent = ({ scrollNav }) => {
  return (
    <Box>
      {/* CAR SERVICES CARDS */}
      {/* <Heading
        fontSize="32px"
        fontWeight="700"
        lineHeight="48px"
        m="4% 0 10% 0"
      >
        Car Services
      </Heading>
      <ServicesCategory data={cardData} />
      <Divider className={styles.dividers} /> */}
      {scrollNav>1000 ? <HomePageNav/> :""}
      {/* CAROUSAL */}
      {/* <HomePageCarousel data={sliderData} hideArrow={false} />
      <Divider className={styles.dividers} /> */}

      {/* NEW CATEGORIES CARDS */}
      {/* <Heading className={styles.headings}>New Category Launches</Heading>
      <HomePageCarousel data={newCategoryLaunches} hideArrow={true} />
      <Divider className={styles.dividers} /> */}

      {/* APPLIANCE CARDS */}
      {/* <Heading className={styles.headings}>Appliances</Heading>
      <Text className={styles.text}>
        Servicing, Repair, Installation & Uninstallation
      </Text>
      <HomePageCarousel data={appliances} hideArrow={true} />
      <Divider className={styles.dividers} /> */}

      {/* SALON SPA SLIDER */}
      {/* <Heading className={styles.headings}>
        Salon, Spa and Massage services
      </Heading>
      <Text className={styles.text}>
        Hygienic & Single use products | Low-contact services
      </Text>
      <HomePageCarousel data={salon} hideArrow={false} />
      <Divider className={styles.dividers} /> */}

      {/* CLEANING PEST CONTROL SLIDER */}
      {/* <Heading className={styles.headings}>Cleaning & Pest Control</Heading>
      <HomePageCarousel data={cleaning} hideArrow={false} />
      <Divider className={styles.dividers} /> */}

      {/* WHEELZ365 LUXURY OFFERINGS */}
      <Heading className={styles.headings}>Why should you be partner with Wheelz 365?</Heading>
      <Text className={styles.text}>
        Top Professionals | Premium Service
      </Text>

        <Flex
        flexDirection="row"
        h="100%"
        w="100%"
        >
        <Flex
            flexDirection="row"
            justifyContent="space-between"
            p={{ base: 2, md: 3, lg: 4, xl: 4}}
            h="100%"
            w="100%"
        >
            <Flex
                flexDirection="column"
                alignItems="center"
                w="33%"
                mr={{ base: 2, md: 3, lg: 4, xl: 5 }}
                mb={{ base: 2, md: 3, lg: 4, xl: 5 }}
            >
                <Icon
                    as={MdGroups}
                    w={{ base: 12, md: 16, lg: 20, xl: 20 }}
                    h={{ base: 12, md: 16, lg: 20, xl: 20 }}
                    mb={{ base: 2, md: 3, lg: 4, xl: 5 }}
                    alignItems='flex-center'
                    color="black.400"
                    fontSize={{ base: 'xl', md: '2xl', lg: '3xl', xl: '4xl'}}
                />
                <Flex flexDirection='column' alignItems="flex-center" width="50%">
                    <Text fontSize={{ base: 'sm', md: 'md', lg: 'lg', xl: 'xl' }} fontWeight="bold">Attract new customer</Text>
                    <Text fontSize={{ base: 'sm', md: 'md', lg: 'md', xl: 'lg' }}>Reach to wider audience through Wheelz 365</Text>
                </Flex>
            </Flex>
            <Flex
                flexDirection="column"
                alignItems="center"
                w="33%"
                mr={{ base: 2, md: 3, lg: 4, xl: 5 }}
                mb={{ base: 2, md: 3, lg: 4, xl: 5 }}
            >
                <Icon
                    as={SiTrustpilot}
                    w={{ base: 12, md: 16, lg: 20, xl: 20 }}
                    h={{ base: 12, md: 16, lg: 20, xl: 20 }}
                    mb={{ base: 2, md: 3, lg: 4, xl: 5 }}
                    color="black.400"
                    fontSize={{ base: 'xl', md: '2xl', lg: '3xl', xl: '4xl'}}
                />
                <Flex flexDirection='column' alignItems="flex-center" width="50%">
                    <Text fontSize={{ base: 'sm', md: 'md', lg: 'lg', xl: 'xl' }} fontWeight="bold">Quality Service</Text>                
                    <Text fontSize={{ base: 'sm', md: 'md', lg: 'md', xl: 'lg' }}>Our professionals are well trained and have on-job expertise</Text>
                </Flex>
            </Flex>
            <Flex
                flexDirection="column"
                alignItems="center"
                w="33%"
                mb={{ base: 2, md: 3, lg: 4, xl: 5 }}
            >
                <Icon
                    as={IoIosMail}
                    w={{ base: 12, md: 16, lg: 20, xl: 20 }}
                    h={{ base: 12, md: 16, lg: 20, xl: 20 }}
                    mb={{ base: 2, md: 3, lg: 4, xl: 5 }}
                    color="black.400"
                    fontSize={{ base: 'xl', md: '2xl', lg: '3xl', xl: '4xl'}}
                />
                <Flex flexDirection='column' alignItems="flex-center" width="50%">
                    <Text fontSize={{ base: 'sm', md: 'md', lg: 'lg', xl: 'xl' }} fontWeight="bold">Onboarding Support</Text>
                    <Text fontSize={{ base: 'sm', md:'md', lg:"md", xl: "md"}}>For any support email us at support@wheelz365.com</Text>
                </Flex>
            </Flex>
        </Flex>
        </Flex>
      <Divider className={styles.dividers} />

      {/* HOME REPAIRS */}
      {/* <Heading className={styles.headings}>Home Repairs</Heading>
      <HomePageCarousel data={homerepairs} hideArrow={true} />
      <Divider className={styles.dividers} /> */}
    </Box>
  );
};

export default JoinUsMidComponent;
