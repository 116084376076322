// src/hooks/useUserLocation.js
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setIsUsa } from "../reducers";

const useUserLocation = () => {
  const [location, setLocation] = useState(null);
  const [isUS, setIsUS] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    // Check if geolocation is supported
    console.log(navigator);
    
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const userLocation = { latitude, longitude };

          // Save location to localStorage
          localStorage.setItem("userLocation", JSON.stringify(userLocation));
          setLocation(userLocation);
          
          // Check if the location is in the USA
          await checkIfUSA(latitude, longitude);
        },
        (error) => {
          console.error("Error fetching location:", error.message);
          setError(error.message);
        }
      );
    } else {
      setError("Geolocation not supported by your browser.");
    }
  }, []);

//   AIzaSyCLm1tl_G3-2uBJVa1pCVfq6BU1Gu4mWpo

  // Function to check if user is in USA using reverse geocoding
  const checkIfUSA = async (latitude, longitude) => {
    try {      
        const apiKey = "AIzaSyCLm1tl_G3-2uBJVa1pCVfq6BU1Gu4mWpo"; // Replace with your Google Maps API key
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
        );

        if (response.data.status === "OK") {
            const results = response.data.results;
            for (const result of results) {
              const countryComponent = result.address_components.find((component) =>
                component.types.includes("country")
              );
              
              if (countryComponent && countryComponent.short_name === "US") {
                console.log(countryComponent.short_name);
                
                setIsUS(true);
                console.log("✅ User is in the USA!");
                return;
              }
            }
          }
      setIsUS(false);
      console.log("User is NOT in the USA!");
    } catch (error) {
      console.error("Error checking country:", error);
      setError("Unable to verify location.");
    }
  };
  
  dispatch(setIsUsa(isUS))
  return { location, isUS, error };
};

export default useUserLocation;
